<template>
  <main-content />
</template>

<script>
import MainContent from '@/layout/MainContent.vue';

export default {
  name: 'InWarehouse',
  components: {
    MainContent,
  },
};
</script>
